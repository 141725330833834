
export function isEqualArrays(a: number[], b: number[]) {

    if (a.length != b.length)
        return false;
    else {
        const aSorted = a.sort();
        const bSorted = b.sort();
        for (let i = 0; i < aSorted.length; i++)
            if (aSorted[i] != bSorted[i])
                return false;
        return true;
    }
}

export function todayISOString() {
    return new Date().toISOString().split("T")[0];
}

