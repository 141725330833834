import React, {useEffect, useState} from "react";
import AreaFilter from "../areaFilter/AreaFilter";
import {ADMIN_NEW_SAK_PATH, API_SAKER_PATH, API_VARSEL_ABONNEMENT_AKTIVERT_PATH} from "../../../constants/urls";
import SakerDataGrid from "../sakerDataGrid/SakerDataGrid";
import {useHistory, useLocation} from "react-router-dom";
import {BottomNavigation, CircularProgress, Grid, makeStyles, useMediaQuery, useTheme,} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import styles from "./sakerOverview.module.css";
import ErrorPage from "../errorPage/ErrorPage";
import ButtonWithAction from "../buttons/ButtonWithAction";
import {Add, Close, Launch} from "@material-ui/icons";
import FilterIcon from "../../../icons/FilterIcon";
import UserInformation from "../../koordinator/UserInformation";
import {useNameMapping} from "../../../context/NameMappingContext";
import SvarfristFilter from "../svarfristFilter/SvarfristFilter";
import SearchChips from "../chips/SearchChips";
import SaksnivaaFilter from "../areaFilter/SaksnivaaFilter";
import {
    isValidSaksnivaaParam,
    Saksnivaa,
    SaksnivaaFylke,
    SaksnivaaKommune,
    SaksnivaaNasjonal
} from "../../../constants/Saksnivaa";
import {isValidSvarfristParam, Svarfrist, SvarfristIkkeUtloept, SvarfristUtloept} from "../../../constants/Svarfrist";
import {QUERY_PARAMS} from "../../../constants/enum";
import SakFilter from "../SakFilter";
import FritekstFilter, {isValidFritekst} from "../fritekstFilter/FritekstFilter";


const useStyles = makeStyles((theme) => ({
    buttonFilterMobile: {
        // display: "flex",
        backgroundColor: theme.palette.info.light,
        // width: "100px",
        width: "fit-content",
        borderRadius: "40px",
        // paddingRight: "30px",
        // paddingLeft: "30px",
        //// padding: "6px 16px",
        // alignSelf: "end",
        maxHeight: "40px",
        boxShadow: "none",
        // marginLeft: "auto",
        marginLeft: "0",
        marginTop: "4px",
        marginBottom: "4px",
        marginRight: "4px",
        textTransform: "none",
        "&:hover, &:focus": {
            boxShadow: "none",
        },
    },
}));

const DEFAULT_SAKER_PR_PAGE: number = 50;

const SakerOverview = () => {
    const { isAuthenticated } = useAuth0();
    const [saker, setSaker] = useState<Sak[]>([]);
    const [sakerPrPage, setSakerPrPage] = useState<number>(DEFAULT_SAKER_PR_PAGE);
    const nameMapping = useNameMapping();
    const allFylkerMedKommuner = nameMapping.allFylkerMedKommuner();
    const [error, setError] = useState(false);
    const [isLoadingSaker, setIsLoadingSaker] = useState<boolean>(false);
    const [, setIsLoadingAbonnementAktivert] = useState<boolean>(false);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [isVarselAbonnementAktivert, setIsVarselAbonnementAktivert] = useState<boolean>(false);
    const btnClasses = useStyles(theme);

    const [searchStackBeforeFetch, setSearchStackBeforeFetch] = useState<string[]>([])
    const [abortController, setAbortController] = useState<AbortController>(new AbortController());

    const idIsLoadingIndicatorMobile = "iAmTheLoadingIndicator4Mobile";

    useEffect(() => {
        if (isMobileScreen) {
            addScrollListenerForSpinner(idIsLoadingIndicatorMobile);
        }
    }, [isMobileScreen]);

    useEffect(() => {
        getSakerForFilter();
        getIsVarselAbonnementAktivert();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        setIsMobileFilterVisible(false);
    }, [isMobileScreen]);

    function getSakerForFilter() {
        setIsLoadingSaker(true);
        const search = location.search;

        const abortSignal = abortController.signal;
        // console.log("Search:", search);
        // console.log("- stack.size: ", searchStackBeforeFetch.length);

        if (abortSignal.aborted) {
            // console.log("- forrige søk er aborted - restoring - do nothing");
        } else {
            fetch(`${API_SAKER_PATH}${search}`, {
                method: "GET",
                signal: abortSignal,
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    throw new Error(res.statusText);
                })
                .then((res) => {
                    // console.log("- setSaker...");
                    setSaker(res);
                    const popped = searchStackBeforeFetch.pop();
                    // console.log("- pop after success: '", popped, "'")
                })
                .catch((error) => {
                    // console.log("error.name: ", error.name);
                    if (error.name === "AbortError") {
                        console.log("Abort!")
                        // console.log("- saker.length: " + saker.length)
                        // console.log("- location.search: " + location.search)
                        // console.log("- lager ny AbortController...")
                        // console.log("- forrige search: ", searchStackBeforeFetch);

                        if (searchStackBeforeFetch.length > 0) {
                            //Restore from before abort
                            const pop = searchStackBeforeFetch.pop();
                            // console.log("- pop for restore '", pop, "'")
                            if (pop !== undefined) {
                                // console.log("- history.replace(", pop, ")");
                                history.replace(pop); //reloader ikke, men trigger useEffect for location

                                //Trenger ikke gjøre noe mer :)
                                // const sakFilterFromQueryParams = getSakFilterFromQueryParams();
                            }
                        }

                        setAbortController(new AbortController);
                    } else {
                        //pop all
                        while (searchStackBeforeFetch.length > 0) {
                            searchStackBeforeFetch.pop();
                        }
                        setError(true);
                        console.log(error);
                    }
                })
                .finally(() => {
                    // console.log("Finally, stack size: ", searchStackBeforeFetch.length);
                    const val = searchStackBeforeFetch.length > 0;
                    // console.log("setIsLoadingSaker(", val, ")");
                    setIsLoadingSaker(val);
                });
        }



    }

    function pushLocationSearchOnStack() {
        searchStackBeforeFetch.push(location.search);
        // console.log("- pushed: '", location.search, "', stack.count: ", searchStackBeforeFetch.length);
    }

    function getIsVarselAbonnementAktivert() {
        setIsLoadingAbonnementAktivert(true);
        fetch(`${API_VARSEL_ABONNEMENT_AKTIVERT_PATH}`, {
            method: "GET",
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                throw new Error(res.statusText);
            })
            .then((res) => {
                setIsVarselAbonnementAktivert(res);
            })
            .catch((error) => {
                setError(true);
                console.log(error);
            })
            .finally(() => {
                setIsLoadingAbonnementAktivert(false);
            });
    }

    function addScrollListenerForSpinner(elementId: string) {
        const listener = () => {
            const element = window.document.getElementById(elementId);
            const viewportHeight = window.innerHeight;
            const scrollTop = window.scrollY;

            if (element != null) {
                const divHeight = element.clientHeight;
                const topOffset = ((viewportHeight - divHeight) / 4) * 3;
                element.style.top = `${topOffset + scrollTop}px`;
                // Other styling in sakerOverview.module.css!
            }
        };
        window.addEventListener('scroll', listener);

        return listener;
    }


    function handleChangeFylker(add: boolean, fylkeNummer: string) {
        const query = new URLSearchParams(location.search);

        if (add) {
            query.append(QUERY_PARAMS.FYLKE, fylkeNummer);
        } else {
            const allFylker = query
                .getAll(QUERY_PARAMS.FYLKE)
                .filter((fylkeId) => fylkeId !== fylkeNummer);
            query.delete(QUERY_PARAMS.FYLKE);
            for (let i = 0; i < allFylker.length; i++) {
                query.append(QUERY_PARAMS.FYLKE, allFylker[i]);
            }

            const fylkeRemoved = allFylkerMedKommuner.filter(
                (fylke) => fylke.nummer === parseInt(fylkeNummer)
            )[0];

            const kommunerToBeRemoved = fylkeRemoved.kommuner.map(
                (k) => `${k.nummer}`
            );

            const kommunerInUrl = query.getAll(QUERY_PARAMS.KOMMUNE);

            const kommunerToKeep = kommunerInUrl.filter(
                (kommuneQueryVal) =>
                    !kommunerToBeRemoved.includes(kommuneQueryVal)
            );

            query.delete(QUERY_PARAMS.KOMMUNE);

            for (let i = 0; i < kommunerToKeep.length; i++) {
                query.append(QUERY_PARAMS.KOMMUNE, kommunerToKeep[i]);
            }
        }
        triggerSearchPath(`?${query.toString()}`);
    }

    function handleChangeKommuner(add: boolean, target: string) {
        const q = new URLSearchParams(location.search);
        if (add) {
            q.append(QUERY_PARAMS.KOMMUNE, target);
        } else {
            const allKommuner = q
                .getAll(QUERY_PARAMS.KOMMUNE)
                .filter((kommuneId) => kommuneId !== target);

            q.delete(QUERY_PARAMS.KOMMUNE);
            for (let i = 0; i < allKommuner.length; i++) {
                q.append(QUERY_PARAMS.KOMMUNE, allKommuner[i]);
            }
        }

        triggerSearchPath(`?${q.toString()}`);
    }

    function handleChangeSaksnivaa(saksnivaa: Saksnivaa, checked: boolean) {
        const q = new URLSearchParams(location.search);
        if (checked) {
            q.append(QUERY_PARAMS.SAKSNIVAA, saksnivaa.paramValue);
        } else {
            let allNivaa = q.getAll(QUERY_PARAMS.SAKSNIVAA).filter(nivaa => nivaa !== saksnivaa.paramValue);
            q.delete(QUERY_PARAMS.SAKSNIVAA);
            for (let i = 0; i < allNivaa.length; i++) {
                q.append(QUERY_PARAMS.SAKSNIVAA, allNivaa[i]);
            }
        }
        triggerSearchPath(`?${q.toString()}`);
    }

    function handleChangeSvarfrist(svarfrist: Svarfrist, checked: boolean) {
        const q = new URLSearchParams(location.search);
        if (checked) {
            q.append(QUERY_PARAMS.SVARFRIST, svarfrist.paramValue);
        } else {
            let allSvarfrist = q.getAll(QUERY_PARAMS.SVARFRIST).filter(frist => frist !== svarfrist.paramValue);
            q.delete(QUERY_PARAMS.SVARFRIST);
            for (let i = 0; i < allSvarfrist.length; i++) {
                q.append(QUERY_PARAMS.SVARFRIST, allSvarfrist[i]);
            }
        }
        triggerSearchPath(`?${q.toString()}`);
    }

    function handleSelectedFylker(e: React.ChangeEvent<HTMLInputElement>) {
        const { checked, value: selectedFylke } = e.target;
        handleChangeFylker(checked, selectedFylke);
    }

    function handleSelectedKommuner(e: React.ChangeEvent<HTMLInputElement>) {
        handleChangeKommuner(e.target.checked, e.target.value);
    }

    function handleNasjonalToggle(e: React.ChangeEvent<HTMLInputElement>) {
        handleChangeSaksnivaa(SaksnivaaNasjonal, e.target.checked);
    }

    function handleFylkeskommunalToggle(e: React.ChangeEvent<HTMLInputElement>) {
        handleChangeSaksnivaa(SaksnivaaFylke, e.target.checked);
    }

    function handleKommunalToggle(e: React.ChangeEvent<HTMLInputElement>) {
        handleChangeSaksnivaa(SaksnivaaKommune, e.target.checked);
    }

    function handleSvarfristUtloptToggle(e: React.ChangeEvent<HTMLInputElement>) {
        handleChangeSvarfrist(SvarfristUtloept, e.target.checked);
    }

    function handleSvarfristIkkeUtloptToggle(e: React.ChangeEvent<HTMLInputElement>) {
        handleChangeSvarfrist(SvarfristIkkeUtloept, e.target.checked);
    }

    function handleChangeFritekst(txt: string | null) {
        // console.log("handleChangeFritekst(", txt, ")")

        const q = new URLSearchParams(location.search);
        q.delete(QUERY_PARAMS.FRITEKST);
        if (txt != null && txt.trim().length > 0) {
            q.append(QUERY_PARAMS.FRITEKST, txt);
        }
        triggerSearchPath(`?${q.toString()}`);
    }

    function handleDeleteFritekst() {
        // console.log("handleDeleteFritekst")
        handleChangeFritekst(null);
    }

    function getSakFilterFromQueryParams() {
        const params = new URLSearchParams(location.search);
        const fylker = params.getAll(QUERY_PARAMS.FYLKE).map((f) => parseInt(f));
        const kommuner = params.getAll(QUERY_PARAMS.KOMMUNE).map((f) => parseInt(f));
        const saksnivaa = params.getAll(QUERY_PARAMS.SAKSNIVAA);
        const svarfrister = params.getAll(QUERY_PARAMS.SVARFRIST);
        const fritekster = params.getAll(QUERY_PARAMS.FRITEKST);

        let numError = 0;

        let validFylker = fylker;
        let validKommuner = kommuner;
        if (!nameMapping.isLoading) {
            validFylker = nameMapping.getFylker(fylker).map(f => f.nummer);
            if (validFylker.length < fylker.length) {
                console.log("", validFylker.length - fylker.length, " ugyldige Fylker i query param")
                numError++;
            }
            validKommuner = nameMapping.getKommuner(kommuner).map(k => k.nummer);
            if (validKommuner.length < kommuner.length) {
                console.log("", validKommuner.length - kommuner.length, " ugyldige Kommuner i query param")
                numError++;
            }
        }

        const validSaksnivaa = saksnivaa.filter(niv => isValidSaksnivaaParam(niv));
        if (validSaksnivaa.length < saksnivaa.length) {
            console.log("", saksnivaa.length - validSaksnivaa.length, " ugyldig Saksnivaa i query param")
            numError++;
        }

        const validSvarfrister = svarfrister.filter(niv => isValidSvarfristParam(niv));
        if (validSvarfrister.length < svarfrister.length) {
            console.log("", svarfrister.length - validSvarfrister.length, " ugyldig Svarfrist i query param")
            numError++;
        }

        let validFritekst = null;
        if (fritekster.length > 1) {
            console.log("For mange Fritekst i query param (" + fritekster.length + ")");
            numError++;
        } else if (fritekster.length == 1) {
            if (fritekster[0] !== null) {
                if (isValidFritekst(fritekster[0])) {
                    validFritekst = fritekster[0];
                } else {
                    console.log("Ugyldig Fritekst i query param");
                    numError++;
                }
            }
        }

        if (numError > 0) {
            console.log("Feil med ", numError, " parametre!")
        }

        // if (numError > 0) {
        //     setError(true);
        //     // return null;
        // }

        return new SakFilter(validSaksnivaa, validFylker, validKommuner, validSvarfrister, validFritekst);
    }

    function handleOnClick() {
        history.push(ADMIN_NEW_SAK_PATH);
    }

    function toggleMobileFilterVisible() {
        setIsMobileFilterVisible((v) => !v);
    }

    function closeFilter() {
        if (isLoadingSaker) {
            abortController.abort();
        }
        toggleMobileFilterVisible();
    }

    function triggerSearchPath(path: string) {
        pushLocationSearchOnStack();
        history.push(path);
    }

    function resetFilter() {
        const query = new URLSearchParams(location.search);
        Object.values(QUERY_PARAMS).forEach((value) => {
            query.delete(value);
        })
        triggerSearchPath(query.toString());
    }

    function handleDeleteFylke(id: number) {
        handleChangeFylker(false, "" + id);
    }

    function handleDeleteKommune(id: number) {
        handleChangeKommuner(false, "" + id);
    }

    if (error) {
        return <ErrorPage />;
    }

    function getMobileMain(sakFilter: SakFilter) {
        return <Grid container>
            <Grid item xs={12} md={9}>
                {isAuthenticated ? (
                    <Grid
                        item
                        xs={12}
                        className={styles.headingContainer}
                    >
                        <h1 className={styles.heading}>Koordinator</h1>
                        <ButtonWithAction
                            title={"Ny sak"}
                            onClick={handleOnClick}
                            color={theme.palette.info.light}
                            icon={<Add/>}
                        />
                    </Grid>
                ) : (
                    <div>
                        <h1 className={styles.heading}>
                            Engasjer deg for natur og friluftsliv
                        </h1>
                        <p className={styles.subtitle}>
                            Høringsradaren gir en oversikt over mange av
                            sakene som er på høring, med konsekvenser
                            for natur og friluftsliv.
                        </p>

                        <a
                            href="https://fnf-nett.no/hoeringsradaren/"
                            className={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >Om Høringsradaren<Launch/>{" "}
                        </a>
                    </div>
                )}
                <div className={styles.buttonContainerMobile}>
                    <div className={sakFilter.isEmpty() ? styles.filterButtonFilterEmptyMobile : styles.filterButtonAbsoultePositionerMobile}>
                        <ButtonWithAction
                            title={sakFilter.isEmpty() ? "Filtrer" : "Filter"}
                            // color={theme.palette.info.light}
                            classes={{root: btnClasses.buttonFilterMobile}}
                            onClick={toggleMobileFilterVisible}
                            icon={<FilterIcon/>}
                        />
                    </div>
                    <SearchChips
                        filter={sakFilter}
                        onResetFilterClick={resetFilter}
                        handleDeleteKommune={handleDeleteKommune}
                        handleDeleteFylke={handleDeleteFylke}
                        handleChangeSaksnivaa={handleChangeSaksnivaa}
                        handleChangeSvarfrist={handleChangeSvarfrist}
                        handleDeleteFritekst={handleDeleteFritekst}
                    />
                </div>
                {/*</div>*/}

                {!isLoadingSaker ? (
                    <SakerDataGrid
                        saker={saker} sakerPrPage={sakerPrPage} setSakerPrPage={setSakerPrPage}
                        sakFilter={sakFilter}
                        isVarselAbonnementAktivert={isVarselAbonnementAktivert}
                    />
                ) : (
                    <div id={idIsLoadingIndicatorMobile} className={styles.isLoadingIndicatorMobile}>
                        <CircularProgress color={"secondary"}/>
                    </div>
                )}
            </Grid>
        </Grid>;
    }

    function getMobileFilter(sakFilter: SakFilter) {
        return <Grid item xs={12} md={3}>
            <div className={styles.filterHeaderContainer}>
                {!sakFilter.isEmpty() && (
                    <ButtonWithAction
                        title={SakFilter.getNullstillTextMedium(sakFilter.getCount())}
                        color={theme.palette.primary.light}
                        onClick={resetFilter}
                    />
                )}
                <ButtonWithAction
                    title={"Avbryt"}
                    color={theme.palette.grey["600"]}
                    disabled={!isLoadingSaker}
                    onClick={closeFilter}
                    icon={<Close/>}
                />
            </div>
            <h2 className={styles.subHeading}>Fritekst</h2>{" "}
            <FritekstFilter
                filter={sakFilter}
                origFritekst={sakFilter.getFritekst()}
                handleClick={handleChangeFritekst}
            />
            <h2 className={styles.subHeading}>Saksnivå</h2>{" "}
            <SaksnivaaFilter
                filter={sakFilter}
                handleNasjonalToggle={handleNasjonalToggle}
                handleFylkeskommunalToggle={handleFylkeskommunalToggle}
                handleKommunalToggle={handleKommunalToggle}
            />
            <h2 className={styles.subHeading}>Område</h2>
            <AreaFilter
                filter={sakFilter}
                handleSelectedFylker={handleSelectedFylker}
                handleSelectedKommuner={handleSelectedKommuner}
            />
            <h2 className={styles.subHeading}>Svarfrist</h2>
            <SvarfristFilter
                filter={sakFilter}
                handleUtloptToggle={handleSvarfristUtloptToggle}
                handleIkkeUtloptToggle={handleSvarfristIkkeUtloptToggle}
            />
            <div id={idIsLoadingIndicatorMobile} className={styles.isLoadingIndicatorMobile}>
                {isLoadingSaker && (
                    <CircularProgress color={"secondary"}/>
                )}
            </div>
            <BottomNavigation showLabels className={styles.bottomNav}>
                <ButtonWithAction
                    title={"Vis " + saker.length + " sak" + (saker.length > 1 || saker.length == 0 ? "er" : "")}
                    color={theme.palette.primary.main}
                    disabled={isLoadingSaker}
                    onClick={toggleMobileFilterVisible}
                />
            </BottomNavigation>
            {/*{prepCenteredMobileSpinner( "centeredDiv")}*/}
        </Grid>;
    }

    function getDesktop(sakFilter: SakFilter) {
        return <Grid container className={"topGrid"}>
            {isAuthenticated && (
                <Grid item xs={12} className={styles.headingContainer}>
                    <h1 className={styles.heading}>Koordinator</h1>
                    <ButtonWithAction
                        title={"Ny sak"}
                        onClick={handleOnClick}
                        color={theme.palette.info.light}
                        icon={<Add/>}
                    />
                </Grid>
            )}
            <Grid item xs={3} sm={3} md={3} lg={2} xl={2} className={"filterGrid"}>
                <h2 className={styles.subHeading}>Fritekst</h2>{" "}
                <FritekstFilter
                    filter={sakFilter}
                    origFritekst={sakFilter.getFritekst()}
                    handleClick={handleChangeFritekst}
                />
                <h2 className={styles.subHeading}>Saksnivå</h2>{" "}
                <SaksnivaaFilter
                    filter={sakFilter}
                    handleNasjonalToggle={handleNasjonalToggle}
                    handleFylkeskommunalToggle={handleFylkeskommunalToggle}
                    handleKommunalToggle={handleKommunalToggle}
                />
                <h2 className={styles.subHeading}>Område</h2>{" "}
                <AreaFilter
                    filter={sakFilter}
                    handleSelectedFylker={handleSelectedFylker}
                    handleSelectedKommuner={handleSelectedKommuner}
                />
                <h2 className={styles.subHeading}>Svarfrist</h2>
                <SvarfristFilter
                    filter={sakFilter}
                    handleUtloptToggle={handleSvarfristUtloptToggle}
                    handleIkkeUtloptToggle={handleSvarfristIkkeUtloptToggle}
                />
            </Grid>

            <Grid item xs={9} sm={9} md={9} lg={10} xl={10} className={"mainGrid"}>
                {!isAuthenticated && (
                    <div>
                        <h1 className={styles.heading}>
                            Engasjer deg for natur og friluftsliv
                        </h1>
                        <p className={styles.subtitle}>
                            Høringsradaren gir en oversikt over mange av
                            sakene som er på høring, med konsekvenser
                            for natur og friluftsliv.
                        </p>
                        <a
                            href="https://fnf-nett.no/hoeringsradaren/"
                            className={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >Om Høringsradaren<Launch/>
                        </a>
                    </div>
                )}

                <div className={styles.chipsContainer}>
                    <SearchChips
                        filter={sakFilter}
                        onResetFilterClick={resetFilter}
                        handleDeleteKommune={handleDeleteKommune}
                        handleDeleteFylke={handleDeleteFylke}
                        handleChangeSaksnivaa={handleChangeSaksnivaa}
                        handleChangeSvarfrist={handleChangeSvarfrist}
                        handleDeleteFritekst={handleDeleteFritekst}
                    />
                </div>

                {!isLoadingSaker ? (
                    <SakerDataGrid
                        saker={saker} sakerPrPage={sakerPrPage} setSakerPrPage={setSakerPrPage}
                        sakFilter={sakFilter}
                        isVarselAbonnementAktivert={isVarselAbonnementAktivert}
                    />
                ) : (
                    <CircularProgress/>
                )}
            </Grid>
            {isAuthenticated && (
                <div>
                    <UserInformation/>
                </div>
            )}
        </Grid>;
    }

    return (
        <div>
            {isMobileScreen && !isMobileFilterVisible && getMobileMain(getSakFilterFromQueryParams())}
            {isMobileScreen && isMobileFilterVisible && getMobileFilter(getSakFilterFromQueryParams())}
            {!isMobileScreen && getDesktop(getSakFilterFromQueryParams())}
        </div>
    );
};

export default SakerOverview;
