import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import { API_OMRAADE_PATH } from "../constants/urls";

// Initial state of the context
type InitialState = {
    isLoading: boolean;
    mapKommunenavn(ids: number[] | undefined): string[];
    mapFylkenavn(ids: number[] | undefined): string[];
    getFylker(ids: number[] | undefined): Fylke[];
    getKommuner(ids: number[] | undefined): Kommune[];
    allFylkerMedKommuner(): Fylke[];
    allFylkeidByKommuneid(): {[key: number]: number};
    getOmraadeTekst(nasjonal: boolean, fylkeNrs: number[], kommuneNrs: number[] | undefined): string;
};

type Props = {
    children: ReactNode;
};

// Creates the context
const NameMappingContext = createContext<InitialState | null>(null);

// provider gives its children access to the values of the context
function NameMappingProvider(props: Props) {
    const { children } = props;
    const [kommunenavnById, setKommunenavnById] = useState<{
        [key: number]: string;
    }>({});
    const [fylkenavnById, setFylkenavnById] = useState<{
        [key: number]: string;
    }>({});
    const [fylkerMedKommuner, setFylkerMedKommuner] = useState<Fylke[]>([]);
    const [fylkeidByKommuneId, setFylkeidByKommuneId] = useState<{[key: number]: number;}>({});

    const [kommuner, setKommuner] = useState<Kommune[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(API_OMRAADE_PATH, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((res) => {
                setKommunenavnById(res.kommunenavnById);
                setFylkenavnById(res.fylkenavnById);
                setFylkerMedKommuner(res.fylkerMedKommuner);
                setFylkeidByKommuneId(res.fylkeidByKommuneId);
                setKommuner(res.kommuner);
                setIsLoading(false);
            });
    }, []);

    const mapKommunenavn = (ids: number[]) => {
        if (ids === undefined) {
            return ["Banan", "Eple", "Appelsin"];
        }

        let kommunenavn: string[] = [];

        ids.map((n) => {
            kommunenavn.push(kommunenavnById[n]);
            return kommunenavn;
        });

        return kommunenavn;
    };

    const mapFylkenavn = (ids: number[]) => {
        if (ids === undefined) {
            return ["Banan", "Eple", "Appelsin"];
        }

        let fylkenavn: string[] = [];

        ids.map((n) => {
            fylkenavn.push(fylkenavnById[n]);
            return fylkenavn;
        });

        return fylkenavn;
    };

    const getFylker = (ids: number[]) => {
        return fylkerMedKommuner.filter(f => ids.includes(f.nummer));
    };

    const getKommuner = (ids: number[]) => {
        return kommuner.filter(k => ids.includes(k.nummer));
    };

    const allFylkerMedKommuner = () => {
        return fylkerMedKommuner;
    };

    const allFylkeidByKommuneid = () => {
        return fylkeidByKommuneId;
    };

    function fylkeHarIkkeKommune(fylke: number, kommuner: number[] | undefined) {
        let isEmptyFylke = true;

        if (kommuner !== undefined) {
            //'every' stops iterating whenever the callback function returns false!
            kommuner.every((knr) => {
                const fylkeForKommune = fylkeidByKommuneId[knr];
                if (fylkeForKommune === fylke) {
                    isEmptyFylke = false;
                    return false;
                }
                return true;
            });
        }
        return isEmptyFylke;
    }

    function getOmraadeTekst(nasjonal: boolean, fylkeNrs: number[], kommuneNrs: number[] | undefined) {

        const omraadeSeparator = ", ";

        let omrString = "";
        if (nasjonal) {
            omrString = "Landsdekkende";

        } else {
            const hasFylker = fylkeNrs !== undefined && fylkeNrs.length > 0;
            const hasKommuner = kommuneNrs !== undefined && kommuneNrs.length > 0;

            if (hasKommuner && hasFylker) {
                const fylkerUtenKommuner: number[] = fylkeNrs.filter((fnr) => {
                    return fylkeHarIkkeKommune(fnr, kommuneNrs)
                });

                const omraader: string[] = [];
                if (kommuneNrs !== undefined) {
                    omraader.push(...(mapKommunenavn(kommuneNrs)));
                }
                if (fylkerUtenKommuner.length > 0) {
                    omraader.push(...(mapFylkenavn(fylkerUtenKommuner)));
                }
                omrString = omraader.join(omraadeSeparator);

            } else if (!hasKommuner && hasFylker) {
                omrString = mapFylkenavn(fylkeNrs).join(omraadeSeparator);
            }
        }
        return omrString;
    }

    return (
        <NameMappingContext.Provider
            value={{
                isLoading,
                mapFylkenavn: mapFylkenavn,
                getFylker: getFylker,
                getKommuner: getKommuner,
                mapKommunenavn: mapKommunenavn,
                allFylkerMedKommuner: allFylkerMedKommuner,
                allFylkeidByKommuneid: allFylkeidByKommuneid,
                getOmraadeTekst: getOmraadeTekst,
            }}
        >
            {children}
        </NameMappingContext.Provider>
    );
}

export default NameMappingProvider;

// this function can be used in the child components of the context provider to get access to the context
export function useNameMapping() {
    const context = useContext(NameMappingContext);
    if (!context) {
        throw new Error(
            `useNameMapping must be used within the NameMappingProvider`
        );
    }

    return context;
}
