import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CreateSak from "./components/koordinator/sak/CreateSak";
import {
    ADMIN_PATH,
    SAK_PATH,
    VARSEL_STOP_PATH,
    BASE_PATH,
    ADMIN_NEW_SAK_PATH,
    ADMIN_EDIT_SAK_PATH,
    ERROR_PATH,
    PAGE_NOT_FOUND_PATH,
} from "./constants/urls";
import Header from "./components/common/header/Header";
import Sak from "./components/common/sak/Sak";
import "../src/styles/App.css";
import KoordinatorMainPage from "./components/koordinator/KoordinatorMainPage";
import PublicMainPage from "./PublicMainPage";
import NameMappingProvider from "./context/NameMappingContext";
import EditSak from "./components/koordinator/sak/EditSak";
import KoordinatorContextProvider from "./context/KoordinatorContext";
import Footer from "./components/common/footer/Footer";
import ErrorPage from "./components/common/errorPage/ErrorPage";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import PageNotFound from "./components/common/pageNotFound/PageNotFound";
import VarselStopp from "./components/common/varselStopp/VarselStopp";
import SortingContextProvider from "./context/SortingContext";

function App() {
    const theme = createTheme({
        overrides: {
            MuiTablePagination: {
                root: {
                    display: "none",
                },
            },

            MuiTableSortLabel: {
                root: {
                    color: "var(--blue)",
                },
                active: {
                    color: "var(--blue)!important",
                },
            },
            MuiBottomNavigation: {
                root: {
                    margin: "0 -8px",
                    position: "sticky",
                    bottom: 0,
                    padding: "12px 0",
                    backgroundColor: "var(--white)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                },
            },
            MuiButton: {
                root: {
                    borderRadius: "40px",
                    padding: "8px 24px",
                    boxShadow: "none",
                    marginLeft: "24px",
                    maxHeight: "40px",
                    textTransform: "none",
                    "&:hover, &:focus": {
                        boxShadow: "none",
                    },
                },
                label: {
                    padding: "5px 16px",
                },
                text: { padding: "4px 16px" },
            },
            MuiPaper: {
                root: {
                    width: "400px",
                    boxShadow: "none",
                },
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "16px",
                    color: "var(--black)",
                    backgroundColor: "var(--white)",
                    padding: "16px",
                    border: "1px solid var(--black)",
                },
            },
            MuiDialogTitle: {
                root: {
                    padding: "0 16px",
                    borderLeft: "6px solid var(--light-orange)",
                    fontWeight: "normal",
                    margin: "24px",
                },
            },
            MuiFormLabel: {
                root: {
                    color: "var(--black)",
                },
            },
            MuiTypography: {
                h6: {
                    margin: " 0 24px",
                    fontSize: "30px",
                },
                colorTextSecondary: {
                    color: "var(--black)",
                },
            },
            MuiDialogActions: {
                root: {
                    margin: "0 24px 12px 0 ",
                },
            },
        },
        typography: {
            fontFamily: [
                "Poppins",
                "Abadi MT Pro",
                "Roboto",
                "Oxygen",
                "Ubuntu",
                "Cantarell",
                "Fira Sans",
                "Droid Sans",
                "Helvetica Neue",
                "sans-serif",
            ].join(","),
        },
        palette: {
            primary: {
                main: "#199581",
                light: "#8BCAB3",
            },
            secondary: {
                main: "#265B6F",
                light: "#58a49b",
            },
            info: {
                main: "#FF6C44",
                light: "var(--light-orange)",
            },
            error: {
                main: "#E76E6E",
                dark: "#B13E43",
            },
            common: {
                white: "#F5F5F5",
                black: "#141414",
            },
            grey: {
                600: "#14141433",
            },
        },
    });
    return (
        <div className="App">
            <MuiThemeProvider theme={theme}>
                <NameMappingProvider>
                    <SortingContextProvider>
                        <KoordinatorContextProvider>
                            <Header />
                            <main className="main">
                                <Switch>
                                    <Route exact path={ADMIN_PATH}>
                                        <KoordinatorMainPage />
                                    </Route>

                                    <Route
                                        path={ADMIN_NEW_SAK_PATH}
                                        component={CreateSak}
                                    />

                                    <Route
                                        path={ADMIN_EDIT_SAK_PATH}
                                        component={EditSak}
                                    />
                                    <Route exact path={SAK_PATH} component={Sak} />
                                    <Route exact path={VARSEL_STOP_PATH} component={VarselStopp} />

                                    <Route
                                        exact
                                        path={BASE_PATH}
                                        component={PublicMainPage}
                                    />
                                    <Route
                                        path={ERROR_PATH}
                                        component={ErrorPage}
                                    />
                                    <Route
                                        path={PAGE_NOT_FOUND_PATH}
                                        component={PageNotFound}
                                    />
                                    <Redirect to={PAGE_NOT_FOUND_PATH} />
                                </Switch>
                            </main>
                        </KoordinatorContextProvider>
                    </SortingContextProvider>
                </NameMappingProvider>
                <Footer />
            </MuiThemeProvider>
        </div>
    );
}

export default App;
