import React from "react";
import { Link } from "react-router-dom";
import {BASE_PATH, FNF_NETT_URL} from "../../../constants/urls";
import styles from "./header.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../footer/logo.svg";
import AdminMenu from "../../koordinator/adminMenu/AdminMenu";

const Header = () => {
    const { isAuthenticated } = useAuth0();

    function conditionalStyling() {
        if (isAuthenticated) {
            return styles.koordinatorHeader;
        } else {
            return styles.header;
        }
    }

    return (
        <header className={conditionalStyling()}>
            <div className={styles.headingContent}>
                <Link to={BASE_PATH} className={styles.link}>
                    <div className={styles.heading}>
                        <h1 className={styles.h1}>Høringsradaren</h1>
                    </div>
                </Link>
                {isAuthenticated ? (
                    <AdminMenu/>
                ) : (
                    <a
                        href={FNF_NETT_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.fnfLogo}
                    >
                        <img src={logo} alt="" />
                        <p className={styles.subtitle}>
                            Forum for natur og friluftsliv
                        </p>
                    </a>
                )}
            </div>
        </header>
    );
};

export default Header;
