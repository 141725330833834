export const sakTypes = [
    "Statlig plan",
    "Regional plan",
    "Fylkesdelplan",
    "Planstrategi",
    "Kommuneplan, samfunnsdel",
    "Kommuneplan, arealdel",
    "Søknad",
    "Kommunedelplan",
    "Temaplan",
    "Veiledende plan for offentlige rom (VPOR)",
    "Varsel om oppstart",
    "Planprogram",
    "Reguleringsplan",
    "Områderegulering",
    "Utbyggingsavtale",
    "Annet",
    "Vern",
    "Gruvedrift",
    "Utslipp/forurensning",
    "Dispensasjon",
    "Kystsoneplan",
    "Vilkårsrevisjon",
    "Forvaltningsplan",
    "Konsesjon",
    "Lov og forskrift",
    "Stortingsmelding",
    "NOU",
];
